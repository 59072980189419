<template>
  <HeaderComponent :isDisable="false" :isHomeNavigation="true" />
  <!-- <FooterNavigationComponent /> -->
  <main class="homePage">
  	<section class="homePage__wrapper container">
  		<figure class="hotline">
		    <img class="hotline__img" src="@/assets/img/hotline.jpg" alt="hotline" />
		    <figcaption class="hotline__description">
		      <p class="hotline__text">
		      	Управление Роспотребнадзора по Запорожской области информирует об организации «горячей линии» по вопросам профилактики гриппа и ОРВИ. 
		      </p> 
					<p class="hotline__text">
						Консультации будут осуществляться по телефону <a href="tel:+79902902517">+7 990-290-25-17</a>, с
			      10.00 час до 15.00 час. ежедневно.
					</p>
		    </figcaption>
		  </figure>
  	</section>
  </main>
</template>

<script>
import HeaderComponent from '@/components/UI/HeaderComponent.vue';
import FooterNavigationComponent from '@/components/UI/Footer/FooterNavigationComponent.vue';
import { useHead } from '@vueuse/head';
import HeaderNavigationComponent from '@/components/UI/HeaderNavigationComponent.vue';

export default {
  components: { HeaderComponent, FooterNavigationComponent, HeaderNavigationComponent },

  setup() {
    useHead({
      title:
        'Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области» | Главная',
      meta: [
        {
          name: 'description',
          content:
            'Федеральное бюджетное учреждение здравоохранения «Центр гигиены и эпидемиологии в Запорожской области»',
        },
      ],
    });
  },
};
</script>
